<template>
  <router-view />
</template>

<script>
import axios from 'axios';
export default {
  name: 'App',
  created: function () {
    axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch('logout')
        }
        throw err;
      });
    });
  },
}
</script>

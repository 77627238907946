<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
        <label for="parent_id">Account:</label>
        <Select2 name="account_id" required v-model.number="checklist_user.account_id" :options="accountOptions" @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
      </div>
      <div class="form-group col-sm-12 required">
          <label for="name">Name:</label>
          <input class="form-control" required="" name="name" type="text" v-model="checklist_user.name">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="name">Email:</label>
        <input class="form-control" required name="email" type="email" v-model="checklist_user.email">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="name">Password:</label>
        <input class="form-control" required name="password" type="password" v-model="checklist_user.password">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Type:</label>
        <select class="form-control" name="type" v-model.number="checklist_user.type">
          <option value="1" style="color:black;">Checker</option>
          <option value="2" style="color:black;">Supervisor</option>
        </select>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>
        <select class="form-control" name="status" v-model.number="checklist_user.status">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div v-if="checklist_user.type == 1" class="form-group col-sm-12 required">
        <label for="status">Checklist Model:</label>
        <select class="form-control" name="status" multiple v-model.number="checklist_user.checklist_user_models">
          <option v-for="models in checklist_models" :key="models" :value="models.id">{{models.checklist_model}}</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/checklist_users">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component'
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('checklist_models', ['checklist_models']),
        ...mapState('checklist_users', ['onRequest']),
    },
    data(){
      return {
        checklist_user: {
          account_id: '',
          email: '',
          name: '',
          password: '',
          status: 1,
          type: 1,
          checklist_user_models: [],
        },
        accountOptions: [],
      }
    },
    components: {
      Select2
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        const {checklist_user} = this
        if(checklist_user.checklist_user_models.length < 1 && checklist_user.type == 1){
          this.error('Please Add Checklist Model First.')
          return
        }
        this.add(checklist_user)
      },
      onChangeAccount(event){
        this.get_checklist_model_by_account({account_id: parseInt(event.id)})
        this.checklist_model_id = ''
      },
      ...mapActions('checklist_users', ['add']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('checklist_models', ['get_checklist_model_by_account']),
      ...mapActions('alert', ['error', 'clear']),
    },
    created() {
      this.$emit('onChildInit', 'Add New Checklist User')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
    }
  }
</script>

<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
        <label for="parent_id">Account:</label>
        <Select2 name="account_id" required v-model.number="checklist.account_id" :options="accountOptions" @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="name">Checklist Model:</label>
        <Select2 name="checklist_model_id" required v-model.number="checklist.checklist_model_id" :options="checklistModelOptions" @change="onChangeModel($event)" @select="onChangeModel($event)"/>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="name">Checklist Date:</label>
        <input class="form-control" required name="checklist_date" type="date" v-model="checklist.checklist_date">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="name">Checklist User:</label>
        <Select2 name="checklist_user_id" required v-model.number="checklist.checklist_user_id" :options="checklistUserOptions"/>
      </div>
      <div v-if="is_use_vehicle == true" class="form-group col-sm-12">
        <label for="name">Vehicle:</label>
        <Select2 name="vehicle_id" v-model.number="checklist.vehicle_id" :options="vehicleOptions"/>
      </div>
      <div class="form-group col-sm-12">
        <label for="name">Driver:</label>
        <Select2 name="driver_id" v-model.number="checklist.driver_id" :options="driverOptions"/>
      </div>
      <div class="form-group col-sm-12">
        <label for="name">Route:</label>
        <Select2 name="route_id" v-model.number="checklist.route_id" :options="routeOptions"/>
      </div>
      <div v-if="checklist_details_items.length > 0" class="col-sm-12" style="border-top: 1px solid black;padding-top: 20px;margin-top: 20px;margin-bottom: 20px;">
        <h4>Detail Checklist</h4>
        <div class="row" v-for="(item, index) in checklist_details_items" :key="index" style="border-top: 1px solid black;padding-top: 10px;margin-top: 10px;">
          <div v-if="item.have_sub_items == 2" class="col-sm-12">
            <div class="row">
              <div class="col-sm-10">
                <div class="form-group">
                  <div class="custom-control custom-switch custom-switch-off-success custom-switch-on-danger">
                    <input type="checkbox" class="custom-control-input" v-bind:id="item.id" :checked="item.is_checked" @change="onCheckItem(item)">
                    <label class="custom-control-label" :for="item.id">{{item.checklist_item}}</label>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">{{item.checklist_value}}<span style="color:red;">{{( item.is_checked && item.checklist_value > 0 ? ' ('+item.checklist_value+')' : '' )}}</span></div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="">Note:</label>
                  <textarea class="form-control" @input="onChangeNote(item, $event.target.value)"></textarea>
                </div>
              </div>
              <div class="col-sm-12">
                <InputImage label="Picture" v-on:selected-image="(...args)=>this.setImage([item,...args])"/>
              </div>
            </div>
          </div>
          <div v-else class="col-sm-12">
            <span style="font-weight:bold;">{{item.checklist_item}}</span>
            <div class="row" v-for="(item2, index2) in item.checklist_sub_items" :key="index2" >
              <div class="col-sm-10">
                <div class="form-group">
                  <div class="custom-control custom-switch custom-switch-off-success custom-switch-on-danger">
                    <input type="checkbox" class="custom-control-input" v-bind:id="item2.id" :checked="item2.is_checked" @change="onCheckItem(item2)">
                    <label class="custom-control-label" :for="item2.id">{{item2.checklist_sub_item}}</label>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">{{item2.checklist_value}}<span style="color:red;">{{( item2.is_checked && item2.checklist_value > 0 ? ' ('+item2.checklist_value+')' : '' )}}</span></div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="">Note:</label>
                  <textarea class="form-control" @input="onChangeNote(item2, $event.target.value)"></textarea>
                </div>
              </div>
              <div class="col-sm-12">
                <InputImage label="Picture" v-on:selected-image="(...args)=>this.setImage([item2,...args])"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/checklists">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import Select2 from 'vue3-select2-component'
  import InputImage from '../components/InputImage.vue'
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('checklist_models', ['checklist_models']),
        ...mapState('checklist_items', ['checklist_items']),
        ...mapState('checklist_users', ['checklist_users']),
        ...mapState('routes', ['routes']),
        ...mapState('vehicles', ['vehicles']),
        ...mapState('drivers', ['drivers']),
        ...mapState('checklists', ['onRequest']),
    },
    data(){
      return {
        checklist: {
          account_id: '',
          checklist_model_id: '',
          checklist_date: moment().format("YYYY-MM-DD"),
          checklist_user_id: '',
          route_id: '',
          vehicle_id: '',
          checklist_details: [],
        },
        is_use_vehicle: false,
        checklist_details_items: [],
        accountOptions: [],
        checklistModelOptions: [],
        checklistUserOptions: [],
        routeOptions: [],
        vehicleOptions: [],
        driverOptions: [],
      }
    },
    components: {
      Select2,
      InputImage,
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        const {checklist, checklist_details_items} = this
        for(let i = 0;i<checklist_details_items.length;i++){
          if(checklist_details_items[i].have_sub_items == 1){
            for(let j = 0;j<checklist_details_items[i].checklist_sub_items.length;j++){
              let item = {
                checklist_item_id: checklist_details_items[i].id,
                checklist_sub_item_id: checklist_details_items[i].checklist_sub_items[j].id,
                checklist_value: checklist_details_items[i].checklist_sub_items[j].checklist_value,
                note: checklist_details_items[i].checklist_sub_items[j].note,
                is_checked: checklist_details_items[i].checklist_sub_items[j].is_checked,
                file_image: checklist_details_items[i].checklist_sub_items[j].file_image,
              }
              checklist.checklist_details.push(item)
            }
          } else {
            let item = {
              checklist_item_id: checklist_details_items[i].id,
              checklist_sub_item_id: null,
              checklist_value: checklist_details_items[i].checklist_value,
              note: checklist_details_items[i].note,
              is_checked: checklist_details_items[i].is_checked,
              file_image:checklist_details_items[i].file_image,
            }
            checklist.checklist_details.push(item)
          }
        }
        this.add(checklist)
      },
      onChangeAccount(event){
        this.get_checklist_model_by_account({account_id: parseInt(event.id)})
        this.get_vehicle_by_account({account_id: parseInt(event.id)})
        this.get_driver_by_account({account_id: parseInt(event.id)})
        this.get_route_by_account({account_id: parseInt(event.id)})
        this.get_checklist_user_by_account({account_id: parseInt(event.id)})
        this.checklist.checklist_model_id = ''
        this.checklist.checklist_user_id = ''
        this.checklist.route_id = ''
        this.checklist.vehicle_id = ''
        this.checklist.driver_id = ''
      },
      onChangeModel(event){
        this.get_checklist_item_by_model({model_id: parseInt(event.id)})
        if(this.checklist_models){
          let fil = this.checklist_models.filter(item => item.id == parseInt(event.id))
          if(fil.length > 0){
            this.is_use_vehicle = fil[0].is_use_vehicle
          }
        }
      },
      onChangeNote(item, val){
        item.note = val
      },
      onCheckItem(item){
        item.is_checked = !item.is_checked
      },
      setImage(args){
        args[0].file_image = args[1]
      },
      ...mapActions('checklists', ['add']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('checklist_models', ['get_checklist_model_by_account']),
      ...mapActions('vehicles', ['get_vehicle_by_account']),
      ...mapActions('drivers', ['get_driver_by_account']),
      ...mapActions('routes', ['get_route_by_account']),
      ...mapActions('checklist_users', ['get_checklist_user_by_account']),
      ...mapActions('checklist_items', ['get_checklist_item_by_model']),
      ...mapActions('alert', ['error', 'clear']),
    },
    created() {
      this.$emit('onChildInit', 'Add New Checklist')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      checklist_models(nextState, prevState){
        if(nextState !== prevState){
          this.checklistModelOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.checklistModelOptions.push({id:item.id, text:item.checklist_model})
            })
          }
          return
        }
      },
      checklist_users(nextState, prevState){
        if(nextState !== prevState){
          this.checklistUserOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.checklistUserOptions.push({id:item.id, text:item.name})
            })
          }
          return
        }
      },
      routes(nextState, prevState){
        if(nextState !== prevState){
          this.routeOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.routeOptions.push({id:item.id, text:item.route_name + ' - ' + item.route_code})
            })
          }
          return
        }
      },
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no})
            })
          }
          return
        }
      },
      drivers(nextState, prevState){
        if(nextState !== prevState){
          this.driverOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.driverOptions.push({id:item.id, text:item.name})
            })
          }
          return
        }
      },
      checklist_items(nextState, prevState){
        if(nextState !== prevState){
          this.checklist_details_items = []
          if(nextState.length > 0){
            for(let i = 0;i<nextState.length;i++){
              let item = JSON.parse(JSON.stringify(nextState[i]))
              item.is_checked = false
              item.file_image = null
              item.note = null
              if(item.have_sub_items == 1){
                for(let j = 0;j<item.checklist_sub_items.length;j++){
                  item.checklist_sub_items[j].is_checked = false
                  item.checklist_sub_items[j].file_image = null
                  item.checklist_sub_items[j].note = null
                }
              }
              this.checklist_details_items.push(item)
            }
          }
          return
        }
      },
    }
  }
</script>

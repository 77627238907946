<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12">
        <label for="account_id">Account:</label>
        <input class="form-control" v-if="checklist_item.account" disabled name="account_id" type="text" v-model="checklist_item.account.account_name">
      </div>
      <div class="form-group col-sm-12 col-md-12">
        <label for="checklist_model_id">Checklist Model:</label>
        <input class="form-control" v-if="checklist_item.checklist_model" disabled name="checklist_model_id" type="text" v-model="checklist_item.checklist_model.checklist_model">
      </div>
      <div class="form-group col-sm-12">
          <label for="name">Checklist Item:</label>
          <input class="form-control" disabled="" name="checklist_item" type="text" v-model="checklist_item.checklist_item">
      </div>
      <div class="form-group col-sm-12">
        <label for="name">Checklist Value:</label>
        <input class="form-control" disabled name="code" type="number" v-model.number="checklist_item.checklist_value" step="any">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>
        <select class="form-control" name="status" v-model.number="checklist_item.status">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="have_sub_items">Have Sub Items:</label>
        <select class="form-control" disabled name="have_sub_items" v-model.number="checklist_item.have_sub_items">
          <option value="1" style="color:black;">Yes</option>
          <option value="2" style="color:black;">No</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/checklist_items">Cancel</router-link>
      </div>
      <div v-if="checklist_item.have_sub_items == 1" class="col-sm-12" style="border-top: 1px solid black;padding-top: 20px;margin-top: 20px;border-bottom: 1px solid black;padding-bottom: 20px;margin-bottom: 20px;">
        <div class="row">
          <div class="col-sm-12">
            <table class="table">
              <thead>
                <tr>
                  <th>Checklist Sub Item</th>
                  <th>Checklist Sub Item Value</th>
                </tr>
              </thead>
              <tr v-for="(item, index) in checklist_item.checklist_sub_items" :key="index">
                <td>{{item.checklist_sub_item}}</td>
                <td>{{item.checklist_value}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    computed: {
        ...mapState('checklist_items', ['checklist_item', 'onRequest']),
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.edit(this.checklist_item)
      },
      ...mapActions('checklist_items', ['edit', 'get']),
    },
    created() {
      this.$emit('onChildInit', 'Edit Checklist Item')
      this.get(this.$route.params.id)
    }
  }
</script>

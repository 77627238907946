<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
        <label for="account_id">Account:</label>
        <Select2 name="account_id" v-model.number="case_incident.account_id" :options="accountOptions" required @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="vehicle_id">Vehicle:</label>
        <Select2 name="vehicle_id" v-model.number="case_incident.vehicle_id" :options="vehicleOptions" required/>
      </div>
      <div class="form-group col-sm-12 col-md-12 ">
        <label for="driver_id">Driver:</label>
        <Select2 name="driver_id" v-model.number="case_incident.driver_id" :options="driverOptions"/>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Type:</label>
        <select class="form-control" required name="status" v-model="case_incident.type">
          <option value="" style="color:black;">Please Select One</option>
          <option value="Driver Behaviour" style="color:black;">Driver Behaviour</option>
          <option value="Emergency" style="color:black;">Emergency</option>
          <option value="False Alarm" style="color:black;">False Alarm</option>
          <option value="Hardware Issue" style="color:black;">Hardware Issue</option>
          <option value="Hijack" style="color:black;">Hijack</option>
          <option value="Vehicle Issue" style="color:black;">Vehicle Issue</option>
          <option value="Route Block" style="color:black;">Route Block</option>
          <option value="Wrong Setting" style="color:black;">Wrong Setting</option>
          <option value="Others" style="color:black;">Others</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <label for="description">Description:</label>
        <textarea class="form-control" name="description" v-model="case_incident.description"></textarea>
      </div>
      <div class="form-group col-sm-12">
        <label for="remarks">Remarks:</label>
        <textarea class="form-control" name="remarks" v-model="case_incident.remarks"></textarea>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>
        <select class="form-control" required name="status" v-model.number="case_incident.status">
          <option value="" style="color:black;">Please Select One</option>
          <option value="2" style="color:black;">In Progress</option>
          <option value="3" style="color:black;">Resolved</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save" :disabled="this.onRequest">
        <router-link class="btn btn-default ml-1" to="/case_incidents">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('drivers', ['drivers']),
        ...mapState('vehicles', ['vehicles']),
        ...mapState('case_incidents', ['onRequest']),
    },
    data(){
      return {
        case_incident: {
          account_id: '',
          vehicle_id: '',
          driver_id: '',
          status: '',
        },
        accountOptions: [],
        vehicleOptions: [],
        driverOptions: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        const {case_incident} = this
        this.add(case_incident)
      },
      ...mapActions('case_incidents', ['add']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('vehicles', {getVehicles: 'get_vehicle_by_account'}),
      ...mapActions('drivers', {getDrivers: 'get_driver_by_account'}),
      onChangeAccount(event){
        this.getVehicles({account_id: parseInt(event.id)})
        this.getDrivers({account_id: parseInt(event.id)})
        this.case_incident.vehicle_id = ''
        this.case_incident.driver_id = ''
      }
    },
    created() {
      this.$emit('onChildInit', 'Add New Case Incident')
      this.get_all()
    },
    components:{
      Select2
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no})
            })
          }
          return
        }
      },
      drivers(nextState, prevState){
        if(nextState !== prevState){
          this.driverOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.driverOptions.push({id:item.id, text:item.name})
            })
          }
          return
        }
      },
    }
  }
</script>

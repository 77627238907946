<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div v-if="checklist_user.account" class="form-group col-sm-12">
          <label for="account_id">Account:</label>
          <input class="form-control" disabled name="account_id" type="text" v-model="checklist_user.account.account_name">
      </div>
      <div class="form-group col-sm-12 required">
          <label for="name">Name:</label>
          <input class="form-control" required="" name="name" type="text" v-model="checklist_user.name">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="name">Email:</label>
        <input class="form-control" disabled name="email" type="email" v-model="checklist_user.email">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Type:</label>
        <select class="form-control" name="type" v-model.number="checklist_user.type">
          <option value="1" style="color:black;">Checker</option>
          <option value="2" style="color:black;">Supervisor</option>
        </select>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>
        <select class="form-control" name="status" v-model.number="checklist_user.status">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div v-if="checklist_user.type == 1" class="form-group col-sm-12 required">
        <label for="status">Checklist Model:</label>
        <select class="form-control" name="status" multiple v-model.number="checklist_user_models">
          <option v-for="models in checklist_models" :key="models" :value="models.id">{{models.checklist_model}}</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/checklist_users">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    computed: {
        ...mapState('checklist_users', ['checklist_user', 'onRequest']),
        ...mapState('checklist_models', ['checklist_models']),
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        const {checklist_user_models, checklist_user} = this
        if(checklist_user_models.length < 1 && checklist_user.type == 1){
          this.error('Please Add Checklist Model First.')
          return
        }
        checklist_user.checklist_user_models = checklist_user_models
        this.edit(this.checklist_user)
      },
      ...mapActions('checklist_users', ['edit', 'get']),
      ...mapActions('checklist_models', ['get_checklist_model_by_account']),
      ...mapActions('alert', ['error', 'clear']),
    },
    data(){
      return {
        checklist_user_models: []
      }
    },
    created() {
      this.$emit('onChildInit', 'Edit Checklist User')
      this.get(this.$route.params.id)
    },
    watch: {
      checklist_user (nextState, prevState) {
        if(nextState !== prevState){
          if(nextState.checklist_user_models){
            this.checklist_user_models = nextState.checklist_user_models.map(item => item.checklist_model_id)
          }
          this.get_checklist_model_by_account({account_id: nextState.account_id})
          return
        }
      },
    }
  }
</script>

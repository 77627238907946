<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div v-if="case_incident.account" class="form-group col-sm-12">
          <label for="parent_id">Account:</label>
          <input class="form-control" disabled name="account_id" type="text" v-model="case_incident.account.account_name">
      </div>
      <div v-if="case_incident.vehicle" class="form-group col-sm-12">
          <label for="vehicle_id">Vehicle:</label>
          <input class="form-control" disabled name="vehicle_id" type="text" v-model="case_incident.vehicle.vehicle_no">
      </div>
      <div class="form-group col-sm-12">
          <label for="incident_code">Case Code:</label>
          <input class="form-control" disabled name="incident_code" type="text" v-model="case_incident.incident_code">
      </div>
      <div class="form-group col-sm-12">
          <label for="created_at">Created At:</label>
          <input class="form-control" disabled name="created_at" type="text" v-model="created_at">
      </div>
      <div class="form-group col-sm-12 col-md-12">
        <label for="driver_id">Driver:</label>
        <select class="form-control" name="driver_id" v-model.number="case_incident.driver_id">
          <option value="">--- Select Driver ---</option>
          <option v-for="d in drivers" :key="d" :value="d.id">{{d.name}}</option>
        </select>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Type:</label>
        <select class="form-control" required name="status" v-model="case_incident.type">
          <option value="" style="color:black;">Please Select One</option>
          <option value="Driver Behaviour" style="color:black;">Driver Behaviour</option>
          <option value="Emergency" style="color:black;">Emergency</option>
          <option value="False Alarm" style="color:black;">False Alarm</option>
          <option value="Hardware Issue" style="color:black;">Hardware Issue</option>
          <option value="Hijack" style="color:black;">Hijack</option>
          <option value="Vehicle Issue" style="color:black;">Vehicle Issue</option>
          <option value="Route Block" style="color:black;">Route Block</option>
          <option value="Wrong Setting" style="color:black;">Wrong Setting</option>
          <option value="Others" style="color:black;">Others</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <label for="description">Description:</label>
        <textarea class="form-control" name="description" v-model="case_incident.description"></textarea>
      </div>
      <div class="form-group col-sm-12">
        <label for="remarks">Remarks:</label>
        <textarea class="form-control" name="remarks" v-model="case_incident.remarks"></textarea>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>
        <select class="form-control" required name="status" v-model.number="case_incident.status">
          <option value="" style="color:black;">Please Select One</option>
          <option value="2" style="color:black;">In Progress</option>
          <option value="3" style="color:black;">Resolved</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save" :disabled="this.onRequest">
        <router-link class="btn btn-default ml-1" to="/case_incidents">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  export default {
    computed: {
        ...mapState('case_incidents', ['case_incident', 'onRequest']),
        ...mapState('drivers', ['drivers']),
        created_at(){
          if(this.case_incident){
            return moment(this.case_incident.created_at).format('LLL')
          }
          return ""
        }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.edit(this.case_incident)
      },
      ...mapActions('case_incidents', ['edit', 'get']),
      ...mapActions('drivers', {getDrivers: 'get_driver_by_account'}),
    },
    created() {
      this.$emit('onChildInit', 'Edit Case Incident')
      this.get(this.$route.params.id)
    },
    watch:{
      case_incident(nextState, prevState){
        if(nextState !== prevState){
          if(nextState.account_id){
            this.getDrivers({account_id : nextState.account_id})
          }
          return
        }
      }
    }
  }
</script>

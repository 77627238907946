<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div v-if="ceiba_device.account" class="form-group col-sm-12">
        <label for="parent_id">Account:</label>
        <input class="form-control" disabled name="account_id" type="text" v-model="ceiba_device.account.account_name">
      </div>
      <div class="form-group col-sm-12">
        <label for="vehicle_id">Vehicle:</label>
        <Select2 name="vehicle_id" v-model.number="ceiba_device.vehicle_id" :options="vehicleOptions"/>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="ceiba_server_id">Ceiba Server:</label>
        <Select2 name="ceiba_server_id" required v-model.number="ceiba_device.ceiba_server_id" :options="ceibaServerOptions"/>
      </div>
      <div class="form-group col-sm-6 required">
        <label for="terminal_id">Terminal ID:</label>
        <input class="form-control" name="terminal_id" required type="text" v-model="ceiba_device.terminal_id">
      </div>
      <div class="form-group col-sm-6 required">
        <label for="channel">Channel:</label>
        <input class="form-control" name="channel" required type="number" v-model.number="ceiba_device.channel" min="0">
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/ceiba_devices">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('ceiba_devices', ['ceiba_device', 'onRequest']),
        ...mapState('vehicles', ['vehicles']),
        ...mapState('ceiba_servers', ['ceiba_servers']),
    },
    components:{
      Select2
    },
    data(){
      return {
        ceibaServerOptions: [],
        vehicleOptions: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.edit(this.ceiba_device)
      },
      ...mapActions('ceiba_devices', ['edit', 'get']),
      ...mapActions('vehicles', ['get_vehicle_by_account']),
      ...mapActions('ceiba_servers', {getCeibaServers: 'get_all'}),
    },
    created() {
      this.$emit('onChildInit', 'Edit Ceiba Device')
      this.get(this.$route.params.id)
      this.getCeibaServers()
    },
    watch: {
      ceiba_device(nextState, prevState){
        if(nextState !== prevState){    
          this.get_vehicle_by_account({account_id: nextState.account_id})
          return
        }
      },
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no +' - '+item.vehicle_code})
            })
          }
          return
        }
      },
      ceiba_servers(nextState, prevState){
        if(nextState !== prevState){
          this.ceibaServerOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.ceibaServerOptions.push({id:item.id, text:item.server_name})
            })
          }
          return
        }
      }
    }
  }
</script>

<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12">
        <label for="account_id">Account:</label>
        <input v-if="checklist.account" class="form-control" disabled name="account_id" type="text" v-model="checklist.account.account_name">
      </div>
      <div class="form-group col-sm-12">
        <label for="checklist_model">Checklist Model:</label>
        <input v-if="checklist.checklist_model" class="form-control" disabled name="checklist_model" type="text" v-model="checklist.checklist_model.checklist_model">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="name">Checklist Date:</label>
        <input class="form-control" disabled name="checklist_date" type="date" v-model="checklist.checklist_date">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="name">Checklist User:</label>
        <Select2 name="checklist_user_id" disabled v-model.number="checklist.checklist_user_id" :options="checklistUserOptions"/>
      </div>
      <div v-if="is_use_vehicle == true" class="form-group col-sm-12">
        <label for="name">Vehicle:</label>
        <Select2 name="vehicle_id" disabled v-model.number="checklist.vehicle_id" :options="vehicleOptions"/>
      </div>
      <div class="form-group col-sm-12">
        <label for="name">Driver:</label>
        <Select2 name="driver_id" disabled v-model.number="checklist.driver_id" :options="driverOptions"/>
      </div>
      <div class="form-group col-sm-12">
        <label for="name">Route:</label>
        <Select2 name="route_id" disabled v-model.number="checklist.route_id" :options="routeOptions"/>
      </div>
      <div v-if="checklist_details_items.length > 0" class="col-sm-12" style="border-top: 1px solid black;padding-top: 20px;margin-top: 20px;margin-bottom: 20px;">
        <h4>Detail Checklist</h4>
        <div class="row" v-for="(item, index) in checklist_details_items" :key="index" style="border-top: 1px solid black;padding-top: 10px;margin-top: 10px;">
          <div v-if="item.have_sub_items == 2" class="col-sm-12">
            <div class="row">
              <div class="col-sm-10">
                <div class="form-group">
                  <div class="custom-control custom-switch custom-switch-off-success custom-switch-on-danger">
                    <input disabled type="checkbox" class="custom-control-input" v-bind:id="item.id" :checked="item.is_checked">
                    <label class="custom-control-label" :for="item.id">{{item.checklist_item}}</label>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">{{item.checklist_value}}<span style="color:red;">{{( item.is_checked && item.checklist_value > 0 ? ' ('+item.checklist_value+')' : '' )}}</span></div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="">Note:</label>
                  <textarea disabled class="form-control" :value="item.note"></textarea>
                </div>
              </div>
              <div class="col-sm-12">
                <div v-if="item.pic_url" class="form-group col-sm-12">
                  <img :src="item.pic_url" height="200"/>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-sm-12">
            <span style="font-weight:bold;">{{item.checklist_item}}</span>
            <div class="row" v-for="(item2, index2) in item.checklist_sub_items" :key="index2" >
              <div class="col-sm-10">
                <div class="form-group">
                  <div class="custom-control custom-switch custom-switch-off-success custom-switch-on-danger">
                    <input disabled type="checkbox" class="custom-control-input" v-bind:id="item2.id" :checked="item2.is_checked">
                    <label class="custom-control-label" :for="item2.id">{{item2.checklist_sub_item}}</label>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">{{item2.checklist_value}}<span style="color:red;">{{( item2.is_checked && item2.checklist_value > 0 ? ' ('+item2.checklist_value+')' : '' )}}</span></div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="">Note:</label>
                  <textarea disabled class="form-control" :value="item2.note"></textarea>
                </div>
              </div>
              <div class="col-sm-12">
                <div v-if="item2.pic_url" class="form-group col-sm-12">
                  <img :src="item2.pic_url" height="200"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Print">
        <button class="btn btn-danger ml-1" value="Print" @click.prevent="exit()">Close</button>
      </div>
    </div>
  </form>
  <div class="modal-backdrop fade show" id="modal_loading">
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import Select2 from 'vue3-select2-component'
  import $ from 'jquery';
  export default {
    computed: {
        ...mapState('checklists', ['checklist', 'onRequest']),
        ...mapState('checklist_items', ['checklist_items']),
        ...mapState('checklist_users', ['checklist_users']),
        ...mapState('routes', ['routes']),
        ...mapState('vehicles', ['vehicles']),
        ...mapState('drivers', ['drivers']),
    },
    methods: {
      ...mapActions('checklists', ['edit', 'get']),
      ...mapActions('alert', ['error', 'clear']),
      ...mapActions('vehicles', ['get_vehicle_by_account']),
      ...mapActions('drivers', ['get_driver_by_account']),
      ...mapActions('routes', ['get_route_by_account']),
      ...mapActions('checklist_users', ['get_checklist_user_by_account']),
      ...mapActions('checklist_items', ['get_checklist_item_by_model']),
      exit(){
        window.close()
      },
      handleSubmit(){
        this.handlePrintChecklist()
      },
      handlePrintChecklist(){
        $('#modal_loading').hide()
        let data = this.checklist
        $('#section-to-print').empty()
        var text_html = `<table style="width:100%;">
          <tr>
            <td style="text-align:left"><h3>`+data.account.account_name+`</h3></td>
            <td></td>
            <td style="text-align:right"><h3>Checklist</h3></td>
          </tr>
          <tr>
            <td colspan="2">
              <table class="table table-sm table-bordered">
                <tr>
                  <th style="text-align:left">Checklist #</th>
                  <td>`+data.code+`</td>
                </tr>
                <tr>
                  <th style="text-align:left">Checklist Date</th>
                  <td>`+moment(data.checklist_date).format("YYYY-MM-DD")+`</td>
                </tr>
                <tr>
                  <th style="text-align:left">Checklist User</th>
                  <td>`+(data.checklist_user ? data.checklist_user.name : '')+`</td>
                </tr>
                <tr>
                  <th style="text-align:left">Checklist Model</th>
                  <td>`+(data.checklist_model ? data.checklist_model.checklist_model : '')+`</td>
                </tr>
                <tr>
                  <th style="text-align:left">Checklist Value</th>
                  <td>`+(data.total_checklist_value)+`</td>
                </tr>
                ` + (data.checklist_model && data.checklist_model.is_use_vehicle == true ? `<tr>
                  <th style="text-align:left">Vehicle No</th>
                  <td>`+(data.vehicle ? data.vehicle.vehicle_code : '')+`</td>
                </tr>
                <tr>
                  <th style="text-align:left">Vehicle Code</th>
                  <td>`+(data.vehicle ? data.vehicle.vehicle_code : '')+`</td>
                </tr>
                <tr>
                  <th style="text-align:left">Driver</th>
                  <td>`+(data.driver ? data.driver.name : '')+`</td>
                </tr>` : '') + `
                <tr>
                  <th style="text-align:left">Route</th>
                  <td>`+(data.route ? data.route.route_name : '')+`</td>
                </tr>
                <tr>
                  <th style="text-align:left">Print Time</th>
                  <td>`+moment().format('lll')+`</td>
                </tr>
              </table>
            </td>
            <td></td>
          </tr>
          <tr>
            <td colspan="3">
              <table class="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th style="text-align:center;">No</th>
                    <th style="text-align:center;">Checklist</th>
                    <th style="text-align:center;">Value</th>
                    <th style="text-align:center;">Checked</th>
                    <th style="text-align:center;">Note</th>
                  </tr>
                </thead><tbody>`
                for(let i=0;i<this.checklist_details_items.length;i++){
                  if(this.checklist_details_items[i].have_sub_items == 2){
                    const {checklist_item, checklist_value, is_checked, note} = this.checklist_details_items[i]
                    text_html +=`<tr>
                      <td class="text-center">`+(i+1)+`</td>
                      <td>`+(checklist_item)+`</td>
                      <td class="text-right">`+(checklist_value ? checklist_value : '')+`</td>
                      <td class="text-center">`+(is_checked)+`</td>
                      <td>`+(note ? note : '')+`</td>
                    </tr>`
                  } else {
                    const {checklist_item, checklist_sub_items} = this.checklist_details_items[i]
                    text_html +=`<tr>
                      <td class="text-center" style="vertical-align:middle" rowspan="`+(checklist_sub_items.length + 1)+`">`+(i+1)+`</td>
                      <td colspan="4">`+(checklist_item)+`</td>
                    </tr>`
                    for(let j = 0;j<checklist_sub_items.length;j++){
                      const {checklist_sub_item, checklist_value, is_checked, note} = checklist_sub_items[j]
                      text_html +=`<tr>
                        <td>`+(checklist_sub_item)+`</td>
                        <td class="text-right">`+(checklist_value ? checklist_value : '')+`</td>
                        <td class="text-center">`+is_checked+`</td>
                        <td>`+(note ? note : '')+`</td>
                      </tr>`
                    }
                  }
                }
                text_html +=`</tbody></table>
            </td>
          </tr>
        </table>`;
        $('#section-to-print').append(text_html);
        window.print()
        $('#section-to-print').empty()
      },
    },
    components: {
      Select2,
    },
    data(){
      return {
        is_use_vehicle: false,
        checklist_details_items: [],
        checklistUserOptions: [],
        routeOptions: [],
        vehicleOptions: [],
        driverOptions: [],
      }
    },
    created() {
      this.$emit('onChildInit', 'Print Checklist')
      this.get(this.$route.params.id)
    },
    mounted(){
      setTimeout(() => this.handlePrintChecklist(this.checklist), 2000);
    },
    watch: {
      checklist (nextState, prevState) {
        if(nextState !== prevState){
          if(nextState.checklist_model && nextState.checklist_model.is_use_vehicle == 1){
            this.is_use_vehicle = true
            this.get_vehicle_by_account({account_id: nextState.account_id})
          }
          this.get_driver_by_account({account_id: nextState.account_id})
          this.get_route_by_account({account_id: nextState.account_id})
          this.get_checklist_user_by_account({account_id: nextState.account_id})
          this.get_checklist_item_by_model({model_id: nextState.checklist_model_id})
          if(nextState.checklist_date){
            nextState.checklist_date = moment(nextState.checklist_date).format("YYYY-MM-DD")
          }
          if(nextState.checklist_date){
            nextState.checklist_date = moment(nextState.checklist_date).format("YYYY-MM-DD")
          }
          return
        }
      },
      checklist_users(nextState, prevState){
        if(nextState !== prevState){
          this.checklistUserOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.checklistUserOptions.push({id:item.id, text:item.name})
            })
          }
          return
        }
      },
      routes(nextState, prevState){
        if(nextState !== prevState){
          this.routeOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.routeOptions.push({id:item.id, text:item.route_name + ' - ' + item.route_code})
            })
          }
          return
        }
      },
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no})
            })
          }
          return
        }
      },
      drivers(nextState, prevState){
        if(nextState !== prevState){
          this.driverOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.driverOptions.push({id:item.id, text:item.name})
            })
          }
          return
        }
      },
      checklist_items(nextState, prevState){
        if(nextState !== prevState){
          this.checklist_details_items = []
          if(nextState.length > 0){
            for(let i = 0;i<nextState.length;i++){
              let item = JSON.parse(JSON.stringify(nextState[i]))
              item.is_checked = false
              item.file_image = null
              item.note = null
              if(item.have_sub_items == 1){
                for(let j = 0;j<item.checklist_sub_items.length;j++){
                  item.checklist_sub_items[j].is_checked = false
                  item.checklist_sub_items[j].file_image = null
                  item.checklist_sub_items[j].note = null
                }
              }
              this.checklist_details_items.push(item)
            }
          }
          const {checklist_details} = this.checklist
          if(checklist_details){
            for(let i= 0;i<checklist_details.length;i++){
              for(let j= 0;j<this.checklist_details_items.length;j++){
                if(this.checklist_details_items[j].id == checklist_details[i].checklist_item_id){
                  if(checklist_details[i].checklist_sub_item_id != null){
                    for(let k= 0;k<this.checklist_details_items[j].checklist_sub_items.length;k++){
                      if(checklist_details[i].checklist_sub_item_id == this.checklist_details_items[j].checklist_sub_items[k].id){
                        this.checklist_details_items[j].checklist_sub_items[k].detail_id = checklist_details[i].id
                        this.checklist_details_items[j].checklist_sub_items[k].is_checked = checklist_details[i].is_checked
                        this.checklist_details_items[j].checklist_sub_items[k].pic_url = checklist_details[i].pic_url
                        this.checklist_details_items[j].checklist_sub_items[k].note = checklist_details[i].note
                        break
                      }
                    }
                  } else {
                    this.checklist_details_items[j].detail_id = checklist_details[i].id
                    this.checklist_details_items[j].is_checked = checklist_details[i].is_checked
                    this.checklist_details_items[j].pic_url = checklist_details[i].pic_url
                    this.checklist_details_items[j].note = checklist_details[i].note
                  }
                  break
                }
              }
            }
          }
          return
        }
      },
    }
  }
</script>
<style>
@media print {
  body * {
    visibility: hidden;
  }
  body{
    -webkit-transform:unset;
    transform:unset;
    min-width: unset !important;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    margin:auto;
    height: auto;
    font-family: Arial;
    line-height: 1.5;
  }
  #section-to-print table tr th {
    padding: 10px;
  }
  #section-to-print table tr td {
    padding: 10px 10px;
  }
}
</style>

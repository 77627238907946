<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
        <label for="server_name">Server Name:</label>
        <input class="form-control" name="server_name" required type="text" v-model="ceiba_server.server_name">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="url">Server URL:</label>
        <input class="form-control" name="url" required type="text" v-model="ceiba_server.url">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="api_port">API Port:</label>
        <input class="form-control" name="api_port" required type="text" v-model="ceiba_server.api_port">
        <small>Default: 12056</small>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="livestream_port">Livestream Port:</label>
        <input class="form-control" name="url" required type="text" v-model="ceiba_server.livestream_port">
        <small>Default: 12060</small>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="username">Server Username:</label>
        <input class="form-control" name="username" required type="text" v-model="ceiba_server.username">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="password">Server Password:</label>
        <input class="form-control" name="password" required type="text" v-model="ceiba_server.password">
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/ceiba_servers">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    computed: {
        ...mapState('ceiba_servers', ['ceiba_server', 'onRequest']),
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.edit(this.ceiba_server)
      },
      ...mapActions('ceiba_servers', ['edit', 'get']),
    },
    created() {
      this.$emit('onChildInit', 'Edit Ceiba Server')
      this.get(this.$route.params.id)
    },
  }
</script>

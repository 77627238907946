<template>
  <div class="row">
    <div class="col-sm-12">
      <Datatable v-bind:entries="ceiba_server" :columns="columns" :title="'Ceiba Servers'"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import { getCapability } from '../_helpers'
  export default {
    computed: {
      ...mapState('ceiba_servers', ['ceiba_servers', 'onRequest']),
      ...mapState('users', ['user']),
      columns(){
        return [
            {name: 'server_name', text: 'Server Name', order: 'asc'},
            {name: 'url', text: 'URL'},
            {name: 'api_port', text: 'API Port'},
            {name: 'livestream_port', text: 'Livestream Port'},
            {name: 'username', text: 'Username'},
            {name: 'action', text: 'Action',
              raw: {
                type: 'Action',
                fields: [
                  getCapability('edit_ceiba_server', this.user) ? {event: 'edit_ceiba_server', text: 'Edit', method: 'get', class: 'btn btn-primary'} : {},
                  getCapability('delete_ceiba_server', this.user) ? {event: 'ceiba_servers/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},
                ]
              }
            },
          ]
      }
    },
    data(){
      return {
        ceiba_server: []
      }
    },
    components:{
      Datatable,
    },
    methods: {
      ...mapActions('ceiba_servers', ['get_all']),
      ...mapActions('alert', ['error', 'clear']),
    },
    created() {
      this.$emit('onChildInit', 'Ceiba Servers', getCapability('add_ceiba_server', this.user) ? '/ceiba_servers/add' : '')
      this.get_all()
    },
    watch: {
      ceiba_servers(nextState, prevState){
        if(nextState !== prevState){
          if(nextState.length > 0){
            this.ceiba_server = []
            for(let i = 0;i<nextState.length;i++){
              this.ceiba_server[i] = JSON.parse(JSON.stringify(nextState[i]))
              let vehicle_odo = 0
              if(this.ceiba_server[i].vehicle){
                vehicle_odo = this.ceiba_server[i].vehicle.odometer
              }
              this.ceiba_server[i].usage_km = vehicle_odo - nextState[i].initial_km
              if(this.ceiba_server[i].status == 1 && this.ceiba_server[i].usage_km >= this.ceiba_server[i].reminder_km){
                this.ceiba_server[i].status_reminder = 2
              } else {
                this.ceiba_server[i].status_reminder = 1
              }
            }
          }
          return
        }
      },
    }
  }
</script>

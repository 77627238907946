<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
        <label for="account_id">Account:</label>
        <Select2 name="account_id" required v-model.number="checklist_item.account_id" :options="accountOptions" @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="checklist_model_id">Checklist Model:</label>
        <Select2 name="checklist_model_id" required v-model.number="checklist_item.checklist_model_id" :options="checklistmodelOptions" />
      </div>
      <div class="form-group col-sm-12 required">
          <label for="name">Checklist Item:</label>
          <input class="form-control" required="" name="checklist_item" type="text" v-model="checklist_item.checklist_item">
      </div>
      <div v-if="checklist_item.have_sub_items == 2" class="form-group col-sm-12">
        <label for="name">Checklist Value:</label>
        <input class="form-control" name="code" type="number" v-model.number="checklist_item.checklist_value" step="any">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>
        <select class="form-control" name="status" v-model.number="checklist_item.status">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="have_sub_items">Have Sub Items:</label>
        <select class="form-control" name="have_sub_items" v-model.number="checklist_item.have_sub_items">
          <option value="1" style="color:black;">Yes</option>
          <option value="2" style="color:black;">No</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/checklist_items">Cancel</router-link>
      </div>
      <div v-if="checklist_item.have_sub_items == 1" class="col-sm-12" style="border-top: 1px solid black;padding-top: 20px;margin-top: 20px;border-bottom: 1px solid black;padding-bottom: 20px;margin-bottom: 20px;">
        <div class="row">
          <div class="form-group col-sm-12">
            <label for="name">Checklist Sub Item:</label>
            <input class="form-control" name="checklist_sub_item" type="text" v-model="checklist_sub_item">
          </div>
          <div class="form-group col-sm-12">
            <label for="name">Checklist Sub Item Value:</label>
            <input class="form-control" name="checklist_value" type="number" v-model.number="checklist_value" step="any">
          </div>
          <div class="form-group col-sm-12">
            <button class="btn btn-success" @click="clickAdd($event)">Add</button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <table class="table">
              <thead>
                <tr>
                  <th>Checklist Sub Item</th>
                  <th>Checklist Sub Item Value</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tr v-for="(item, index) in checklist_item.checklist_sub_items" :key="index">
                <td>{{item.checklist_sub_item}}</td>
                <td>{{item.checklist_value}}</td>
                <td><button class="btn btn-sm btn-danger" @click="clickRemove($event, index)">Remove</button></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('checklist_models', ['checklist_models']),
        ...mapState('checklist_items', ['onRequest']),
    },
    data(){
      return {
        checklist_item: {
          checklist_item: '',
          checklist_model_id: '',
          have_sub_items: 2,
          status: 1,
          checklist_value: '',
          checklist_sub_items: []
        },
        checklist_sub_item: '',
        checklist_value: '',
        checklistmodelOptions: [],
        accountOptions: [],
      }
    },
    components: {
      Select2
    },
    methods: {
      clickAdd(event){
        event.preventDefault()
        if(this.checklist_sub_item !== ''){
          this.checklist_item.checklist_sub_items.push({checklist_sub_item: this.checklist_sub_item, checklist_value: this.checklist_value})
          this.checklist_sub_item = ''
          this.checklist_value = ''
        }
      },
      clickRemove(event, index){
        event.preventDefault()
        this.checklist_item.checklist_sub_items.splice(index, 1)
      },
      handleSubmit(){
        this.clear()
        if(this.onRequest) return;
        const {checklist_item} = this
        if(checklist_item.have_sub_items == 1 && checklist_item.checklist_sub_items.length < 1){
          this.error('Please Add Sub Items First.')
          return
        }
        this.add(checklist_item)
      },
      onChangeAccount(event){
        this.get_checklist_model_by_account({account_id: parseInt(event.id)})
        this.checklist_model_id = ''
      },
      ...mapActions('checklist_items', ['add']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('checklist_models', ['get_checklist_model_by_account']),
      ...mapActions('alert', ['error', 'clear']),
    },
    created() {
      this.$emit('onChildInit', 'Add New Checklist Item')
      this.get_all()
    },
    watch: {
      checklist_models(nextState, prevState){
        if(nextState !== prevState){
          this.checklistmodelOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.checklistmodelOptions.push({id:item.id, text:item.checklist_model})
            })
          }
          return
        }
      },
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
    }
  }
</script>

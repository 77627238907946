<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
          <label for="parent_id">Account:</label>
          <select class="form-control" required name="account_id" v-model.number="checklist_model.account_id">
            <option value="">--- Please Select One ---</option>
            <option v-for="acc in accounts" :key="acc" :value="acc.id">{{acc.account_name}}</option>
          </select>
      </div>
      <div class="form-group col-sm-12 required">
          <label for="name">Checklist Model:</label>
          <input class="form-control" required="" name="checklist_model" type="text" v-model="checklist_model.checklist_model">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="name">Code:</label>
        <input class="form-control" required name="code" type="text" v-model="checklist_model.code">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="is_use_vehicle">Use Vehicle:</label>
        <select class="form-control" name="is_use_vehicle" v-model.number="checklist_model.is_use_vehicle">
          <option value="1" style="color:black;">Yes</option>
          <option value="2" style="color:black;">No</option>
        </select>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>
        <select class="form-control" name="status" v-model.number="checklist_model.status">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/checklist_models">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('checklist_models', ['onRequest']),
    },
    data(){
      return {
        checklist_model: {
          code: '',
          checklist_model: '',
          is_use_vehicle: 2,
          status: 1,
        }
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        const {checklist_model} = this
        this.add(checklist_model)
      },
      ...mapActions('checklist_models', ['add']),
      ...mapActions('accounts', ['get_all']),
    },
    created() {
      this.$emit('onChildInit', 'Add New Checklist Model')
      this.get_all()
    },
  }
</script>
